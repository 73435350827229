<template>
<div id="banner" class="app-download">
  <section class="left-app-download">
    <div class="app-download-content">
      <h1><span class="yellow">消费增值</span> 互享未来</h1>
      <p>AI社交+绿⾊电商+消费剩余价值链盟</p>
      <div class="tips">App下载</div>
      <div class="app-application">
        <a href="https://h5.tokbuy.io/download">
        <div class="down-content">
          <img src="../assets/ios.png" />
          <div class="down-tips">
              <div>IOS Download</div>
              <div>Apple Store</div>
          </div>
        </div>
        </a>
        <a href="https://h5.tokbuy.io/download">
        <div class="down-content">
          <img src="../assets/Android.png" />
          <div class="down-tips">
              <div>Andorid Download</div>
              <div>Google Play</div>
          </div>
          
        </div>
      </a>
      </div>
      <div class="block-chain">
      <p>支持链</p>
      <img src="../assets/anwang_logo.png" />
      <p class="chain-name">安网Safe</p>
      </div>
    </div>
  </section>
  <section class="right-app-download">
    <img src="../assets/banner.png" />
  </section>
</div>
</template>

<script setup>
</script>

<style scoped>

.app-download{
  background:#000;
  color: white;
  display:flex;
}
a{
  text-decoration:none;
  color:white;
}
.app-application{
  margin-top:20px;
  display:flex;
}
.down-tips{
  position:relative;
  top:5px;
}
.app-application .down-content{
  display:flex;
  flex-wrap: wrap; /* 允许换行 */
  font-size:11px;
  margin-right:20px;
  background-color:#3D544D;
  padding:8px 15px;
  border-radius:5px;
  cursor:pointer;
}

.down-content img{
  width:30px;
  height:30px;
  margin-right:8px;
}
.tips{
  color:#ADB2B1;
}
.left-app-download{
  width:60%;
}
.app-download-content{
  padding-left:80px;
}
.app-download-content h1{
  font-size:50px;
}
.app-download-content p{
  color:#ADB2B1;
  font-size:30px;
}

.right-app-download img{
  width:100%;
}

.right-app-download{
  width:40%;
}

.block-chain{
  display:flex;
}

.block-chain p{
  color:#E9B200;
}
.block-chain img{
  width:40px;
  height:40px;
  position:relative;
  top:31px;
  left:20px;
}
p.chain-name{
  margin-left:30px;
  color:#fff;
}

@media (max-width: 600px) {
  .app-download-content h1{
    font-size:20px;
  }
  .app-download-content p{
    font-size:12px;
  }
  .app-download-content{
    padding-left:20px;
  }
  .tips{
    font-size:10px;
  }
  .down-tips{
    display:none;
  }
  .down-content img{
    width:20px;
    height:20px;
  }
  .block-chain img{
    width:40px;
    height:40px;
    position:relative;
    top:4px;
  }
}
</style>
