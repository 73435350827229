<template>
  <section class="partners">
    <h2>TokBuy 合作生态</h2>
    <div class="partner-logos">
      <div class="scrolling-wrapper">
        <img src="../assets/part/icon1.png" />
        <img src="../assets/part/icon2.png" />
        <img src="../assets/part/icon3.png" />
        <img src="../assets/part/icon4.png" />
        <img src="../assets/part/icon5.png" />
        <img src="../assets/part/icon6.png" />
        <img src="../assets/part/icon7.png" />
        <img src="../assets/part/icon8.png" />
        <img src="../assets/part/icon9.png" />
        <img src="../assets/part/icon10.png" />
        <!-- 克隆一份图片，用于无缝滚动 -->
        <img src="../assets/part/icon1.png" />
        <img src="../assets/part/icon2.png" />
        <img src="../assets/part/icon3.png" />
        <img src="../assets/part/icon4.png" />
        <img src="../assets/part/icon5.png" />
        <img src="../assets/part/icon6.png" />
        <img src="../assets/part/icon7.png" />
        <img src="../assets/part/icon8.png" />
        <img src="../assets/part/icon9.png" />
        <img src="../assets/part/icon10.png" />
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.partners {
  color: white;
  padding: 50px 0;
  padding-left: 20px;
}

.partner-logos {
  overflow: hidden; /* 隐藏超出的部分 */
  width: 100%;      /* 确保父容器占满可视区域 */
}

.scrolling-wrapper {
  display: flex;
  width: max-content; /* 根据内容自动适应宽度 */
  animation: scroll 30s linear infinite; /* 连续滚动 */
}

.partner-logos img {
  margin: 0 15px;
  max-width: 100px;
}

/* 定义无缝滚动动画 */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* 滚动一半的内容宽度 */
  }
}

</style>