<template>
  <section id="news" class="carousel-section">
    <div class="carousel-container">
      <button @click="prev" :disabled="currentIndex === 0" class="nav-button left">
        &lt;
      </button>
      <div class="carousel-wrapper">
        <div
          class="carousel-track"
          :style="{
            transform: `translateX(-${currentIndex * (100 / visibleCards)}%)`,
            transition: 'transform 0.5s ease-in-out'
          }"
        >
          <div class="card" v-for="(card, index) in cards" :key="index">
            <a :href="card.url"><img :src="card.img"  /></a>
          </div>
        </div>
      </div>
      <button
        @click="next"
        :disabled="currentIndex >= maxIndex"
        class="nav-button right"
      >
        &gt;
      </button>
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from 'vue';

// 示例卡片数据
const cards = ref([
  { img:  require('../assets/news/news1.png')  , url:"https://medium.com/@tokbuy365/profile-of-tokbuy-social-e-commerce-chain-alliance-brand-ambassador-and-spokesperson-chen-entian-e73a147b525c" },
  { img:  require('../assets/news/news2.png')  , url:"https://medium.com/@tokbuy365/background-of-the-tokbuy-social-e-commerce-chain-alliance-platform-8f5ba340028c" },
  { img:  require('../assets/news/news3.png')  , url:"https://medium.com/@tokbuy365/the-underlying-logic-of-tokbuys-integration-of-ai-dlt-advanced-technology-and-green-circular-9f71b36d95be" },
  { img:  require('../assets/news/news4.png')  , url:"https://medium.com/@tokbuy365/tokbuy-social-e-commerce-chain-alliance-platforms-advantages-and-competitive-strengths-184df771cd1a" },
  { img:  require('../assets/news/news5.png')  , url:"https://medium.com/@tokbuy365/establishment-of-the-southern-silk-road-international-cooperation-organization-a-new-era-of-a0812bf3c5d0" },
  { img:  require('../assets/news/news6.png')  , url:"https://medium.com/@tokbuy365/vision-and-mission-of-the-tokbuy-social-e-commerce-chain-alliance-platform-69ee828abefe" },
  { img:  require('../assets/news/news7.png')  , url:"https://medium.com/@tokbuy365/background-of-the-tokbuy-social-e-commerce-chain-alliance-platform-8f5ba340028c" },
  // 添加更多卡片如果需要
]);

const visibleCards = 2.2; // 每次可视的卡片数量
const currentIndex = ref(0);
const maxIndex = computed(() => Math.ceil(cards.value.length / visibleCards) );

// 切换到下一组卡片
const next = () => {
  if (currentIndex.value < maxIndex.value) {
    currentIndex.value += 1;
  }
};

// 切换到上一组卡片
const prev = () => {
  if (currentIndex.value > 0) {
    currentIndex.value -= 1;
  }
};
</script>

<style scoped>
.carousel-section {
  color: white;
  padding-top: 50px;
}

.carousel-container {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* 根据需求调整 */
  margin: 0 auto;
}

.carousel-wrapper {
  overflow: hidden;
  width: 100%;
}

.carousel-track {
  display: flex;
}

.card {
  flex: 0 0 40%; /* 每个卡片占视口的25%，即4个卡片 */
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

.card img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto 10px;
}

.nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.nav-button.left {
  margin-right: 10px;
}

.nav-button.right {
  margin-left: 10px;
}
</style>
