<template>
  <section id="block" class="blockchain">
    <div class="blockchain-info">
        <div class="line">
            <p class="title">支持链</p>
            <img class="chain-img" src="../assets/anwang_logo.png" />
            <p class="chain-name">安网Safe</p>
        </div>

        <div class="line text">
            安网（SAFE）是由新加坡SAFE基金会于2014年10月推出的、去中心化的、专注数字资产隐私保护的区块链安全支付和隐私计算平台。距今2021年7月为止，已经有7年历史，期间经历从安网1.0、安网2.0、安网3.0等3个大版本的迭代更替，在各种创新技术和商业应用上进行了众多有益的探索。安网4.0携多年的行业实践经验和成果，以密码学理论和隐私计算为基础，以技术创新和应用创新为导向，着眼隐私保护，强化安全支付，优化资产隐私，引入跨链资产和稳定币，打造safeswap交易场，拓展更多商业化应用，旨在构建一个全球范围的隐私保护生态圈。
        </div>

        <div class="line">
            <span class="btn" @click="anwang">
                <span>了解更多</span>
                <img class="arrow" src="../assets/ArrowRight.png" />
            </span>
            
        </div>
    </div>
    <div class="blockchain-img">
        <img src="../assets/anwang_bg.png" />
    </div>
  </section>
</template>

<script setup>
const anwang = () => {
    window.location.href = 'https://www.anwang.com'
    window.open('https://www.anwang.com/', '_blank');
};
</script>

<style scoped>
.blockchain{
    display:flex;
}
.blockchain-info{
    width:70%;
}
.blockchain-img img{
    width:500px;
}
p.chain-name{
  margin-left:30px;
  color:#fff;
}
.blockchain {
  padding: 50px 0;
}
.btn{
    background:#E9B200;
    color:#000;
    font-size:12px;
    padding:10px 20px;
    border-radius:20px;
    cursor:pointer;
}
.line img.arrow{
    width:16px;
    height:16px;
    margin-left:20px;
    position:relative;
    top:3px;
}
p.title{
    color:#E9B200;
}
p.title,p.chain-name{
    font-size:40px;
    padding:0;
    margin:0;
    margin-top:10px;
}
p.chain-name{
    margin-left:35px;
    
}
.line img.chain-img{
    width:40px;
    height:40px;
    position:relative;
    top:20px;
    left:20px;
}

.line{
    display:flex;
    margin-bottom:40px;
}
.text{
    width:600px;
    text-indent:2rem;
    line-height: 2; 

}
.line img{
    width:288px;
    height:65px;
}
@media (max-width: 600px) {
    .blockchain{
        display:block;
        padding:0 20px;
    }
    p.title{
        font-size:20px;
        padding:0;
        margin:0;
        margin-top:10px;
    }
    .text{
        width:330px;
    }
    .line img{
        width:115px;
        height:26px;
        margin-top:10px;
    }

    .btn{
        font-size:12px;
        padding:10px 20px;
        border-radius:20px;
        cursor:pointer;
    }
    .line img.arrow{
        width:16px;
        height:16px;
        margin-left:20px;
        margin-top:0;
    }

    .blockchain-img{
       display:flex;
       justify-content: center; /* 水平居中 */
       padding-bottom:10px;
    }
}
</style>
