<template>
  <section id="system" class="system-overview">
    <h2><span class="yellow">TokBuy</span> 生态系统</h2>
    <div class="system-features">
      <div class="title">把挑战变成乐趣</div>
      <div class="sub-title">赚取积分、升级并解锁专属奖励。加入社区驱动的游戏</div>
      <div class="cards">
        <div class="card">
          <p><span class="card-icon">TB</span></p>
          <p class="card-title">商户友好</p>
          <p class="card-content">商户受益于 SSC 实用程序、交易费用分配和市场增长支持</p>
        </div>
        <div class="card">
          <p><span class="card-icon">TB</span></p>
          <p class="card-title">数字市场</p>
          <p class="card-content">赚取和使用积分、优惠券并利用算力来访问各种产品</p>
        </div>
        <div class="card">
          <p><span class="card-icon">TB</span></p>
          <p class="card-title">娱乐中心</p>
          <p class="card-content">一个充满娱乐、游戏和社交体验的世界，有助于生态系统的发展</p>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script setup>
</script>

<style scoped>
.system-overview h2{
  text-align:center;
  font-size:40px;
}
.system-overview {
  color: white;
  padding: 30px 0;
}
.system-features {
  height:500px;
  background:url('@/assets/bg.png');
  background-size: 100% 500px;
  background-repeat:no-repeat;
}
.cards{
  display:flex;
  justify-content: center;
  margin-top:80px;
  margin-left:150px;
  margin-right:150px;
}
.card-title{
  font-size:18px;
  font-weight:bold;
}
.card-icon {
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #E9B200;
  text-align: center;
  font-size:12px;
}
.card {
  height: 200px;
  width: 33%;
  margin-right:30px;
  background: rgba(255, 255, 255, 0.001); /* 半透明背景 */
  backdrop-filter: blur(5px); /* 毛玻璃模糊效果 */
  -webkit-backdrop-filter: blur(10px); /* 兼容 Safari */
  border-radius: 10px; /* 圆角效果 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 添加阴影增强毛玻璃效果 */
  padding-left:24px;
  padding-top:24px;
}
.card-content{
  font-size:14px;
  font-weight:lighter;
}

.title{
  padding-left:150px;
  font-size:40px;
  font-weight:bold;
}
.sub-title{
  padding-left:150px;
  font-size:30px;
  margin-top:40px;
  font-weight:lighter;
  width:600px;
}

@media (max-width: 600px) {
  .system-features {
    background-size: 100% 300px;
    height:300px;
  }
  .title{
    padding-left:20px;
    font-size:30px;
  }
  .sub-title{
    padding-left:20px;
    font-size:18px;
    width:300px;
    margin-top:20px;
  }

  .cards{
    margin-left:10px;
    margin-right:10px;
    margin-top:10px;
  }
  .card-icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    font-size:10px;
  }
  .card {
    height: 180px;
    width: 33%;
    margin-right:10px;
    padding-left:4px;
    padding-top:4px;
  }
}
</style>
