import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'

// 导入翻译文件
import en from './locales/en.json'
import zh from './locales/zh.json'

// 设置可用语言和默认语言
const messages = {
  en: en,
  zh: zh
}
const app = createApp(App)
const i18n = createI18n({
  locale: 'en', // 默认语言
  fallbackLocale: 'en', // 回退语言
  messages,
})
app.use(i18n)

app.mount('#app')
