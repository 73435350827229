<template>
  <section id="ssc" class="execution-officer">
    <h1>为 Tokbuy 生态系统提供动力</h1>
    <p>在我们的电子商务平台中，商业联盟币证明了区块链在提供现实世界实用性和促进繁荣经济方面的力量。</p>
    <div class="info">
        <ul>
            <li>
                <div class="title">POS</div>
                <div class="type">共识机制</div>
            </li>
            <li>
                <div class="title">AnWang</div>
                <div class="type">区块链</div>
            </li>
            <li>
                <div class="title">10B</div>
                <div class="type">代币发行量</div>
            </li>
            <li>
                <div class="title">SSC</div>
                <div class="type">代币名称</div>
            </li>
            <li>
                <div class="title">SAFE</div>
                <div class="type">网络</div>
            </li>
        </ul>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
h1{
    font-size:50px;
}
p{
    font-size:13px;
}
.execution-officer {
  padding: 50px 0;
  text-align: center;
  color: white;
}
.info{
    margin-top:48px;
    border-radius:5px;
    background: rgba(39, 50, 47, 0.24); /* 带透明度的背景 */
}
ul {
  list-style: none; 
  padding: 0; 
  margin: 0; 
  color:#fff;
  display:flex;
}

li {
    width:25%;
    margin: 0; 
    padding: 0;
    padding:64px 0;
}
.title{
    font-size:30px;
    font-weight:bold;
}
.type{
    font-size:12px;
    margin-top:18px;
    color:#E9B200;
}
@media (max-width: 600px) {
    li {
        padding:32px 0;
    }
   .title{
        font-size:20px;
        font-weight:bold;
    }
    .type{
        font-size:9px;
        margin-top:18px;
        color:#E9B200;
    } 
    .info{
        margin-left:5px;
    }
}

</style>
