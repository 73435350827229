<template>
  <header>
    <nav>
      <img src="../assets/logo.png" alt="TokBuy Logo" />
      <ul>
          <li><a href="#statistics">最新动态</a></li>
          <li><a href="#system">生态系统</a></li>
          <li><a href="#block">支持链</a></li>
          <li><a href="#ssc">代币生态</a></li>
          <li><a href="">白皮书</a></li>
      </ul>
    </nav>
  </header>
</template>

<script setup>
</script>

<style scoped>
header {
  background-color: black;
  color: white;
}
nav img {
  width: 100px;
  margin-left: 20px;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
nav ul {
  display: flex;
  list-style-type: none;
  margin-right:40px;
  width:700px;
  padding: 20px 0;
}
nav ul li{
  width:20%;
  text-align: right;
}
nav ul li a {
  position: relative;
  display: inline-block;
  padding-bottom: 12px;
}

nav ul li a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: center; /* 从中间向两边扩展 */
  transition: transform 0.3s ease;
}

nav ul li a:hover::after {
  transform: scaleX(1); /* 放大到原始宽度 */
}


nav ul li a {
  color: white;
  text-decoration: none;
}
@media (max-width: 600px) {
    nav img {
    width: 100px;
    margin-left: 20px;
  }
  nav ul {
    margin-right:15px
  }
}
</style>
