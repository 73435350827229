<template>
  <section id="statistics" class="statistics">
    <div class="stat">
      <h3 id="downloads"></h3>
      <p>下载数量</p>
    </div>
    <div class="stat">
      <h3 id="activeUsers"></h3>
      <p>活跃用户</p>
    </div>
  </section>
</template>

<script setup>
import { onMounted } from 'vue';
import { CountUp } from 'countup.js';

onMounted(() => {
  const downloadsCountUp = new CountUp('downloads', 12650000);
  if (!downloadsCountUp.error) {
    downloadsCountUp.start();
  }

  const activeUsersCountUp = new CountUp('activeUsers', 1500000);
  if (!activeUsersCountUp.error) {
    activeUsersCountUp.start();
  }
});
</script>

<style scoped>
.statistics {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  color: #fff;
}
.statistics p {
  padding: 0;
  margin: 0;
  line-height: 0;
}
.statistics h3 {
  font-size: 35px;
  color: #E9B200;
  padding: 0;
}
.stat {
  margin: 0 20px;
  text-align: center;
}
</style>
