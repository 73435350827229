<template>
  <div class="foot">
    <img class="foot-logo" src="../assets/TokBuy.png" />
    <div class="buttons">
      <div class="btn1">
        <a href="https://h5.tokbuy.io/download">下载应用程序</a>
      </div>
      <div class="btn2">
        白皮书
      </div>
    </div>

    <ul>
      <li><a href="https://t.me/tokbuy/"><img src="../assets/icon_link1.png" /></a></li>
      <li><a href="https://x.com/tokbuy365"><img src="../assets/icon_link3.png" /></a></li>
      <li><a href="https://www.youtube.com/@TOKBUY"><img src="../assets/icon_link5.png" /></a></li>
    </ul>
  </div>

  <Footer>
    <p>© 2024 TokBuy. All rights reserved.</p>
  </Footer>
</template>

<script setup>
</script>

<style scoped>
a{
  text-decoration:none;
  color:#000;
}
Footer {
  background-color: black;
  padding: 20px;
  text-align: center;
  color: white;
}
.foot-logo{
  width:100px;
}
.foot{
  margin-top:48px;
}
.buttons{
  display:flex;
  margin-top:15px;
}
.btn1{
  background:#E9B200;
  color:#000;
  border-radius:20px;
  padding:8px 20px;
  font-size:14px;
  cursor:pointer;
}
.btn2{
  margin-left:15px;
  border-radius:20px;
  border:#fff solid 1px;
  padding:8px 20px;
  font-size:14px;
  cursor:pointer;
}

ul {
  list-style: none; 
  padding: 0; 
  margin: 0; 
  color:#fff;
  display:flex;
  margin-top:20px;
}

li {
    margin: 0; 
    padding: 0;
    margin-right:10px;
}
li img{
  width:32px;
  height:32px;
  cursor:pointer;
}
@media (max-width: 600px) {
  .foot{
    margin-left:20px;
  }
}
</style>
