<template>
  <section class="mode">
    <img src="../assets/mode.png" />
  </section>
</template>

<script setup>
</script>

<style scoped>
.mode{
    padding:0 10px;
}
.mode img{
    width:100%;
}
</style>
